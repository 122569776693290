import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { colors } from '../../styles/variables'

export const ColorButton = styled.button`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  font-family: Source Sans Pro;
  color: ${colors.plain.incoWhite};
  padding: 16px 24px;
  background: ${colors.gradient.red};
  border: none;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: 200ms;
  white-space: nowrap;
  & :hover {
    @media (min-width: 900px) {
      transform: scale(${({ disabled }) => (disabled ? 1 : 1.03)});
      transition: 200ms;
    }
  }
`

export const ColorLink = styled(Link)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  font-family: Source Sans Pro;
  color: ${colors.plain.incoWhite};
  padding: 16px 24px;
  background: ${colors.gradient.red};
  border: none;
  letter-spacing: 1px;
  border-radius: 6px;
  white-space: nowrap;
  & :hover {
    @media (min-width: 900px) {
      transform: scale(1.03);
      transition: 200ms;
    }
  }
`

export const FlatLink = styled(Link)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  font-family: Source Sans Pro;
  color: ${colors.plain.incoWhite};
  padding: 16px 24px;
  background: transparent;
  border: 1px solid ${colors.plain.incoWhite};
  letter-spacing: 1px;
  border-radius: 6px;
  white-space: nowrap;
  & :hover {
    @media (min-width: 900px) {
      transform: scale(1.03);
      transition: 200ms;
    }
  }
`

export const FlatButton = styled.button`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  font-family: Source Sans Pro;
  color: ${colors.plain.incoWhite};
  padding: 16px 24px;
  background: transparent;
  border: 1px solid ${colors.plain.incoWhite};
  letter-spacing: 1px;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  & :hover {
    @media (min-width: 900px) {
      transform: scale(1.03);
      transition: 200ms;
    }
  }
`
