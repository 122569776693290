import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import DataContext from '../components/Common/DataContext'

import { colors } from '../styles/variables'
import { ColorLink, FlatLink } from '../components/Common/Buttons'
import getAsset from '../services/getAsset'
import IndexLayout from '../layouts'

const Subtitle = styled.div`
  color: ${colors.plain.nuances7};
  font-weight: normal;
  font-size: 18px;
  max-width: 408px;
  text-align: center;
  padding-top: 16px;
`

const Title = styled.h1`
  font-weight: bold;
  text-align: center;
  font-family: Montserrat;
  line-height: 47px;
  max-width: 408px;
  padding-top: 145px;
  @media (max-width: 800px) {
    padding-top: 100px;
  }
`

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
`

const CardContainer = styled.div`
  border-radius: 32px;
  box-shadow: 0px 6px 12px rgba(11, 4, 11, 0.2);
  background: ${colors.gradient.nuances2};
  padding: 48px;
  max-width: 500px;
  margin-top: -67px;
  flex-direction: column;
  z-index: 2;
  @media (max-width: 600px) {
    padding: 32px;
    margin-top: -67px;
  }

  /* top: 375px; */
`
const CardTitle = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  margin: 0;
  padding-bottom: 16px;
`

const WomenContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 30px;
`

const WomenIllu = styled(Img)`
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const CardText = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: ${colors.plain.nuances7};
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 80px;
  }
  margin-top: 70px;
`

const TextColumn = styled.div`
  margin-left: 116px;
  margin-right: 116px;
  @media (max-width: 900px) {
    margin-right: 0;
    margin-left: 0;
    order: ${({ order = '' }: { order?: string }) => order};
  }
  @media (max-width: 600px) {
    padding: 0 16px;
  }
  max-width: 408px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Cards = styled(Img)`
  @media (max-width: 900px) {
    padding-right: 0;
  }
`

const IllustrationColumn = styled.div`
  align-items: center;
  width: 400px;
  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  position: relative;
`

const RecoColumn = styled(IllustrationColumn)`
  margin-right: 116px;
  @media (max-width: 900px) {
    margin-right: 0;
  }
`

const ContentColumn = styled(IllustrationColumn)`
  margin-left: 116px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
`

const ButtonWrapper = styled.div`
  padding-top: 32px;
`

const IndexPage = () => {
  const contextValue = useContext(DataContext)
  const { step } = contextValue
  const womenAsset = getAsset('women')
  const recoCardsAsset = getAsset('reco-cards')
  const contentCardsAsset = getAsset('content-cards')

  return (
    <IndexLayout>
      <HomeContainer>
        <Title>Become a More Inclusive Workplace</Title>
        <Subtitle>A free resource for employers seeking to improve diversity and gender inclusion.</Subtitle>
        <ColorLink to={step === 2 ? '/recommendations' : '/survey'} style={{ marginTop: 32 }}>
          Get recommendations
        </ColorLink>
        <WomenContainer>
          <WomenIllu fluid={womenAsset?.childImageSharp?.fluid} />
          <CardContainer>
            <CardTitle>Prioritizing Gender Equality in the Tech Sector</CardTitle>
            <CardText>
              The information technology sector is on track to be worth $5.2 trillion by the end of 2020, driving the creation of a new
              economy where jobs are created through, or as a result of, digital innovation. However, across the globe women only represent
              a fraction of those employed in the tech industry. Economically speaking, if the world’s regions matched the best performing
              countries in terms of gender parity, an additional $12 trillion of annual GDP would be realized by 2025. The question for us
              as employers is how might we play our role in promoting diversity and inclusivity throughout the whole employee experience,
              from the recruitment process to the day-to-day and beyond. This is precisely the goal of the Pow'Her In Tech initiative, led
              by INCO.ORG and supported by the Chanel Foundation : empowering women to join and thrive in tech sectors internationally.
            </CardText>
          </CardContainer>
        </WomenContainer>
        <Section>
          <TextColumn order="2">
            <CardTitle>Get custom recommendations to Pow’Her Tech</CardTitle>
            <CardText>
              Accelerate efforts to empower women to join and thrive in the tech sector locally and internationally. Be challenged to create
              more inclusive culture, intentionally. Start here!
            </CardText>
            <ButtonWrapper>
              <ColorLink to={step === 2 ? '/recommendations' : '/survey'}>Get recommendations</ColorLink>
            </ButtonWrapper>
          </TextColumn>
          <RecoColumn>
            <Cards fluid={recoCardsAsset?.childImageSharp?.fluid} />
          </RecoColumn>
        </Section>
        <Section>
          <ContentColumn>
            <Cards fluid={contentCardsAsset?.childImageSharp?.fluid} />
          </ContentColumn>
          <TextColumn>
            <CardTitle>Access our full catalogue of Pow’Her in Tech content</CardTitle>
            <CardText>
              Browse the full Pow’Her in Tech resource and get inspired to make change to support greater diversity and inclusion in tech.
            </CardText>
            <ButtonWrapper>
              <FlatLink to="/content">Read content</FlatLink>
            </ButtonWrapper>
          </TextColumn>
        </Section>
      </HomeContainer>
    </IndexLayout>
  )
}

export default IndexPage
